@import 'tale';

body {
  background-image: url("/assets/bg1.jpg");
  background-position: left top;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
}

body .site {
  padding: 0 40px;
  padding: 0 2.857142857rem;
  margin-top: 48px;
  margin-top: 3.428571429rem;
  margin-bottom: 48px;
  margin-bottom: 3.428571429rem;
  background-color: #fff;
  max-width: 960px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  -webkit-box-shadow: 0px 10px 16px -9px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 10px 16px -9px rgba(0,0,0,0.7);
  box-shadow: 0px 10px 16px -9px rgba(0,0,0,0.7);
}

body .nav {
  width: 100%;
  background-color: #fff;
}

body .topbar {
  position: fixed;
  top: 0;
  width: 100%;
  content-align: center;
  background-color: #fff;
  height: 45px;
  text-align: center;
  -webkit-box-shadow: 0px 10px 16px -9px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 10px 16px -9px rgba(0,0,0,0.7);
  box-shadow: 0px 10px 16px -9px rgba(0,0,0,0.7);
}

.like-box {
  position: fixed;
  right: 40px;
  bottom: 40px;
  @media (max-width: 700px) {
    right: 5px;
    bottom: 5px;
  }
}

.post-image {
  max-height: 400px;
}

.search-input {
  display: flex;
  align-content: right;
  width: 100%;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.results-container {
  margin: 0 0 0 0;
  min-height: 0px;
  padding: 0 0 0 0;
}

.navul {
  width: 90%;
}

/* Share buttons */
.sharebuttons {
  width: 300px;
  margin: 0 auto 0 auto;
}

.sharebuttons ul {
  margin: 20px 0 0 0;
  text-align: center;
}

.sharebuttons ul li {
  display: inline;
}

.sharebuttons ul li a {
  text-decoration: none;
}

.sharebuttons ul li svg {
  width: 40px;
  height: 40px;
}

.sharebuttons .reddit svg {
  fill: #FF4500;
}

.sharebuttons .hn svg {
  fill: #F0652F;
}

.sharebuttons .twitter svg {
  fill: #1DA1F2;
}

.sharebuttons .youtube svg {
  fill: #1DA1F2;
}

/* Social Network Icons */
.social-icon {
  margin: 0 auto 0 auto;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,0.2);
  padding-bottom: 15px;
}

.social-icon ul li {
  display: inline;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
  line-height: 20px;
}

.social-icon ul {
  margin: 20px 0 0 0;
  padding: 0 0 0 0;
  text-align: center;
}

.social-icon ul li svg {
  width: 20px;
  height: 40px;
  vertical-align: middle;
}

.social-icon ul li a {
  text-decoration: none;
}

.btn-svg svg {
  margin-top: -2.5px;
  fill: #FFFFFF;
}

.btn-a a {
  color: #FFFFFF;
  padding: 5px 10px 5px 10px;
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  border: 0px solid #000000;
}

.btn-reddit a {
  background-color: #FF4500;
}

.btn-twitter a {
  background-color: #1DA1F2;
}

.btn-linkedin a {
  background-color: #0077B5;
}

.btn-linkedin a {
  background-color: #0077B5;
}

.btn-facebook a {
  background-color: #4172B8;
}

.btn-instagram a {
  background-color: #E4405F
}

.btn-github a {
  background-color: #181717
}

.btn-patreon a {
  background-color: #FF424D;
}

.btn-youtube a {
  background-color: #FF0000
}

.btn-twitch a {
  background-color: #9146FF;
}

.btn-code-stats a {
  background-color: #181717;
}

.btn-telegram a {
  background-color: #2CA5E0;
}

.btn-mastodon a {
  background-color: #2b90d9;
}

.btn-bluesky a {
  background-color: #0285FF;
}

.site-build {
  font-size: 10px;
  color: #AAAAAA;
  height: 20px;
  text-align: center;
  width: 100%;
}

.language-bar {
  width: 100px;
  margin: -40px 0 0 auto;
  height: 32px;
}


.twitch-embed {
  width: 100%;
}

.post-image-div {
  display: flex;
  width: 100%;
  text-align: center;
  align-content: center;
}

.post-image-2 {
  margin: 0 auto;
}

.catalogue-item-2 {
  width: 100%;
}

div.truth-table-holder {
  width: 100%;
    display: flex;
}

div.truth-table-container {
  flex-grow: 1;
}

/* Truth Table */
table.truth {
  border-collapse:collapse;
  margin:0 auto;
}

table.truth th {
  border-bottom:1px solid black;
  text-align: center;
  padding:.4em;
  font-weight: normal;
}

table.truth td {
  border:0;
  padding:.4em;
  text-align: center;
}

table.truth .dv {
  border-right:1px solid black;
}

table.truth .mc {
  color:red;
}
table.truth .tv {
  color:green;
  font-weight: bold;
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0)
  }
  20%,
  60% {
    transform: rotate(-25deg)
  }
  40%,
  80% {
    transform: rotate(10deg)
  }
}

.latex svg {
  max-width: 100%
}
